<template>
  <div>
    <section>
      Redirecionando...
    </section>
  </div>
</template>

<script>
export default {
  name: "RedirectPayment",
  beforeCreate() {
    if (this.$route.params.siteId === 'g' && this.$route.params.paymentId) {
      window.location.href = process.env.VUE_APP_GRELO_URL + this.$route.params.paymentId;
    }
  }
};
</script>
